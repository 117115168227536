<template>
  <el-dialog
    append-to-body
    width="500px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      class="elFormDom"
      label-width='100px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
      :size="subUnitSize"
    >
      <!--  只有总公司账号才有权限选择开票公司,不可修改 -->
      <el-form-item v-if="this.icId == 0" label="开票单位：" prop="invoice_company_id">
        <el-select v-model="form.data.invoice_company_id" placeholder="请选择" clearable filterable :disabled="this.isChange">
          <el-option
            v-for="item in invoiceCompanyOpt"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='base' label='缴纳基数：'>
        <el-input
          v-model='form.data.base'
          clearable
          placeholder='请输入'
        ><span slot="suffix">元</span>
        </el-input>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item prop='company_rate' label='单位占比：'>
            <el-input
              v-model.number='form.data.company_rate'>
              <span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='company_amount' label='金额：'>
            <el-input
              v-model='form.data.company_amount'>
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item prop='employees_rate' label='员工占比：'>
            <el-input
              v-model.number='form.data.employees_rate'
            ><span slot="suffix">%</span>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item prop='employees_amount' label='金额：'>
            <el-input
              v-model='form.data.employees_amount'>
              <span slot="suffix">元</span>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  import {getStorage} from '@/storage'
  export default {
    name: 'SystemUnitEdit',
    props:['invoiceCompanyOpt'],
    data() {
      var checkRate = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('请输入税率'));
        }
        if (!Number.isInteger(value)) {
          return callback(new Error('税率只能输入数字'));
        } else {
          callback();
        }
      };
      return {
        subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
        icId: Number(getStorage('icId')), // 登录账号的身份id（0：总公司, 2:鹰潭分公司）
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            invoice_company_id: '',
            base: '',
            company_rate: '',
            employees_rate: '',
            company_amount: '',
            employees_amount: '',
          },
          rules: {
            invoice_company_id: [{ required: true, message: "请选择开票单位", trigger: "change" }],
            base: [
              { required: true, message:'请输入缴纳基数', trigger: 'change'},
              {
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式，如：188.88",
                trigger: "change",
              }
            ],
            company_rate: [
              { required: true, message: '单位占比不能为空'},
              { type: 'number', message: '单位占比必须为数字值'}
            ],
            employees_rate: [
              { required: true, message: '员工占比不能为空'},
              { type: 'number', message: '员工占比必须为数字值'}
            ],
            company_amount: [{
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式",
                trigger: "change",
              }
            ],
            employees_amount: [{
                pattern: REG_EXP.money,
                message: "请填写正确的金额格式",
                trigger: "change",
              }
            ],
          }
        },
        optOrgType: [],
        optOrg: [],
        options: [{
          value: 1,
          label: '企业'
        },
        {
          value: 2,
          label: '员工'
        }],
        settlementType: [
          {
            value: 1,
            label: '当月薪资'
          },
          {
            value: 2,
            label: '上月薪资'
          }
        ],
      }
    },
    watch: {
      invoiceCompanyOpt(val) {
        this.invoiceCompanyOpt = val
      }
    },
    methods: {
      // 获取五险详情
      getDetail(row) {
        this.isChange = true
        this.$http.post('/admin/fundLevel/detail', { id: row.id }).then(res => {
          if(res.code === 1) {
            common.deepClone(this.form.data, res.data)
          } else {
            this.$message.error(res.msg)
          }
        })
        
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           if(this.icId != 0) {
            // 如果分公司就不要传开票企业id
            delete this.form.data.invoice_company_id;
           }
           this.form.loading = true;
           let apiUrl = !this.isChange ? '/admin/fundLevel/create' : '/admin/fundLevel/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success("操作成功！")
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
<style scoped>
.elFormDom ::v-deep .el-select {
  width: 320px !important;
}
</style>